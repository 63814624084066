import axios from "axios";
import React, { Component } from "react";
import { DataLayer, pageContext, userContext } from "./analytics";
import { BlankSlate } from "@sie/kodama-ui-components";
import config from "./config";
import ErrorMsg from "./ErrorMsg";
import {
  adjustIframeSrcUrl,
  constructAdditionalPathForIframeSrc,
  getAppIdentifier,
  getCleanUrl,
  getCorrectPath,
  parseQueryObj,
  parseQueryParameters,
  findMenuItem,
  removeSandboxQueryParameters,
} from "./utils";
import SandboxIcon from "./images/iconid_sandbox.png";

var kodamaAPIBase = config.kodama.apiUrl;

const failOverComponent = (
  <ErrorMsg message="There was a problem reaching the app. Please try again later." />
);

const SandboxNotSupportedComponent = (
  <div id="page-doesnt-support-sandbox-mode" style={{ margin: "30px" }}>
    <BlankSlate
      text={
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              marginBottom: "10px",
            }}
          >
            <img alt="Sandbox Icon" src={SandboxIcon} /> Sandbox
          </div>
          This page isn't currently available as part of Sandbox.
        </div>
      }
    />
  </div>
);

const {
  KA_PATH_KEY,
  MENU_ITEM_KEY,
  KODAMA_APP_PATH_MESSAGE,
  APP_ID_KEY,
  CONTEXT_VALUE,
  SANDBOX_MODE,
} = config;

export default class ProxyComponent extends Component {
  state = { failOverComponent: null, src: "" };

  resetIframe = () => {
    //clearing state.src defeats shouldComponentUpdate restrictions and forces iframe to refresh
    this.setState({ src: "" }, () => {
      this.fetchLandingPageUrl();
    });
  };
  parseQueryParameters = () => {
    // uses mutable history.location.search because this component is updated on history.push()
    if (this.props.history.location.search) {
      const queryParameterString =
        this.props.history.location.search.substring(1);
      if (queryParameterString) {
        return parseQueryParameters(queryParameterString);
      }
    }
    return {};
  };
  onFrameLocationChange = (event) => {
    if (event.data.message === KODAMA_APP_PATH_MESSAGE) {
      let { appIdentifier, path, applicationGroup } = this.props.match.params;
      const { payload } = event.data;
      if (payload.app_id) {
        appIdentifier = getAppIdentifier(payload.app_id);
      }

      const queryObj = this.parseQueryParameters();
      if (payload[APP_ID_KEY]) queryObj[APP_ID_KEY] = payload[APP_ID_KEY];
      if (payload[MENU_ITEM_KEY])
        queryObj[MENU_ITEM_KEY] = payload[MENU_ITEM_KEY];
      if (payload[CONTEXT_VALUE])
        queryObj[CONTEXT_VALUE] = payload[CONTEXT_VALUE];
      if (payload.path) queryObj[KA_PATH_KEY] = payload.path;
      const searchParamStr = parseQueryObj(queryObj);
      const url = getCleanUrl(
        applicationGroup,
        appIdentifier,
        getCorrectPath(path)
      );
      this.props.history.replace(url + searchParamStr, event.data.message);
    }
  };
  fetchLandingPageUrl = () => {
    const {
      appGroupContextType,
      menuItems = [],
      isSandboxUser,
      isGroupSandboxAware,
      sandboxEnabled,
    } = this.props;
    const { appIdentifier, path } = this.props.match.params;
    let queryParamObject = Object.entries(this.parseQueryParameters()).reduce(
        (acc, keyVal) => {
          if (keyVal[0] !== APP_ID_KEY && keyVal[0] !== MENU_ITEM_KEY) {
            acc[keyVal[0]] = keyVal[1];
          }

          return acc;
        },
        {}
      ),
      landingPageUrl = "",
      options = {};
    if (queryParamObject[CONTEXT_VALUE]) {
      options.params = { contextValue: queryParamObject[CONTEXT_VALUE] };
    }

    const baseUrl = `${kodamaAPIBase}/navigation/v1/menuItem/app/${appIdentifier}`;
    axios
      .get(baseUrl + getCorrectPath(path), options)
      .then((response) => {
        const permission = (response.data && response.data.permission) || "";

        landingPageUrl = response.data.landingPageUrl;
        const additionalParamsIndex = landingPageUrl.indexOf("?");

        //landing page has query params. Need to extract them from landing page

        if (additionalParamsIndex !== -1) {
          const additionalParams = parseQueryParameters(
            landingPageUrl.substring(additionalParamsIndex + 1)
          );
          landingPageUrl = landingPageUrl.substring(0, additionalParamsIndex);
          queryParamObject = Object.assign(
            {},
            additionalParams,
            queryParamObject
          );
        }

        removeSandboxQueryParameters(queryParamObject, isSandboxUser);

        let additionalPath = constructAdditionalPathForIframeSrc(
          queryParamObject,
          appGroupContextType
        );

        let adjustedUrl = adjustIframeSrcUrl(landingPageUrl, additionalPath);

        const pageIsSandboxAware = response.data.sandboxAware;

        const failOverComponent =
          isGroupSandboxAware && sandboxEnabled && !pageIsSandboxAware
            ? SandboxNotSupportedComponent
            : null;

        this.setState({ src: adjustedUrl, failOverComponent }, () => {
          const page = pageContext.get();
          page.type = "landing";
          page.appName = appIdentifier;
          const menuItem = findMenuItem(
            menuItems,
            (item) => item.url === getCorrectPath(path)
          );
          if (menuItem) {
            page.menuItem = menuItem.itemId;
          }

          const user = userContext.get();
          user.permission = permission;
          const dataLayer = new DataLayer({ page, user });
          dataLayer.send("pageload");
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ src: "", failOverComponent });
      });
  };
  componentWillUnmount() {
    window.removeEventListener("message", this.onFrameLocationChange);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.src === prevState.src &&
      prevState.failOverComponent === this.state.failOverComponent
    ) {
      // if ProxyComponent updates without change in landingPageUrl, trigger setting landing page
      // this occurs when only param changes like context switch
      this.fetchLandingPageUrl();
    }
  }

  getContextParam = (key, search) => {
    if (search && search.substring(1)) {
      const queryParameterString = search.substring(1);
      if (queryParameterString) {
        return parseQueryParameters(queryParameterString)[key];
      }
    }
  };
  shouldComponentUpdate(nextProps, nextState) {
    //this is necessary when transitioning from proxy component back to homepage
    const { appIdentifier, path } = this.props.match.params;
    const { appIdentifier: nextAppId, path: nextPath } = nextProps.match.params;
    const { action } = nextProps.history;
    const currentSearch = this.props.location.search;
    const nextSearch = nextProps.location.search;

    const differentApp = appIdentifier !== nextAppId || path !== nextPath; // change in appId or path
    const differentIframeSrc = nextState.src !== this.state.src; //change in landingPageUrl
    const diffContext =
      this.getContextParam(CONTEXT_VALUE, currentSearch) !==
      this.getContextParam(CONTEXT_VALUE, nextSearch); // change in context
    const diffSandbox =
      this.getContextParam(SANDBOX_MODE, currentSearch) !==
      this.getContextParam(SANDBOX_MODE, nextSearch); // change in context
    const diffMenu = this.getContextParam(MENU_ITEM_KEY, currentSearch)
      ? this.getContextParam(MENU_ITEM_KEY, currentSearch) !==
        this.getContextParam(MENU_ITEM_KEY, nextSearch)
      : false; // change in menu item if the menu item is already a parameter

    // this message is used to update the history and top level URL without updating the iframe.
    if (nextProps.location.state === KODAMA_APP_PATH_MESSAGE) {
      return false;
    }

    if (differentApp && action === "POP") {
      //X23-366.  side effect of iframe on the back button operation when the result
      //navigates to different multi page app.
      return false;
    }

    return (
      differentApp ||
      differentIframeSrc ||
      diffContext ||
      diffSandbox ||
      diffMenu ||
      nextState.failOverComponent !== this.state.failOverComponent
    );
  }
  componentDidMount() {
    window.addEventListener("message", this.onFrameLocationChange);
    this.fetchLandingPageUrl();
  }
  render() {
    if (this.state.failOverComponent === null) {
      console.log("update iframe: " + this.state.src);
      return (
        <iframe
          className="iframe-embedded-app"
          src={this.state.src}
          title="Application Page"
          allowFullScreen="true"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        ></iframe>
      );
    } else {
      return this.state.failOverComponent;
    }
  }
}
